import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { Index } from "Comp/Views/Index";

function App() {
  
  return (
    <Index />
  );
}

export default App;
